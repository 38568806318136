// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as BadgeScss from "./Badge.scss";
import * as JsxRuntime from "react/jsx-runtime";

var css = BadgeScss;

function Badge(props) {
  var __className = props.className;
  var className = __className !== undefined ? __className : "";
  var tmp;
  switch (props.badgeType) {
    case "Colocation" :
        tmp = css.colorColocation;
        break;
    case "Cloud" :
        tmp = css.colorCloud;
        break;
    case "Internet" :
        tmp = css.colorInternet;
        break;
    case "Network" :
        tmp = css.colorNetwork;
        break;
    case "BareMetal" :
        tmp = css.colorBareMetal;
        break;
    case "Default" :
        tmp = css.colorDefault;
        break;
    
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx("button", {
                    children: props.children,
                    className: Cx.cx([
                          css.badge,
                          tmp,
                          className
                        ]),
                    disabled: true
                  }),
              className: css.wrapper
            });
}

var make = Badge;

export {
  css ,
  make ,
}
/* css Not a pure module */
